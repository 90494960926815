define("ember-bootstrap/templates/components/bs-modal/header/close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wyDnnQLs",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"button\"],[24,\"aria-label\",\"Close\"],[24,0,\"close\"],[17,1],[24,4,\"button\"],[4,[38,0],[\"click\",[32,0,[\"handleClick\"]]],null],[12],[2,\"\\n  \"],[10,\"span\"],[14,\"aria-hidden\",\"true\"],[12],[2,\"×\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-modal/header/close.hbs"
    }
  });

  _exports.default = _default;
});