define("ember-bootstrap/templates/components/bs-accordion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7BOeWJWD",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"accordion\",\" \",[29]]]],[24,\"role\",\"tablist\"],[17,1],[12],[2,\"\\n  \"],[18,2,[[30,[36,1],null,[[\"item\",\"change\"],[[30,[36,0],[[32,0,[\"itemComponent\"]]],[[\"selected\",\"onClick\"],[[32,0,[\"isSelected\"]],[32,0,[\"doChange\"]]]]],[32,0,[\"doChange\"]]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-accordion.hbs"
    }
  });

  _exports.default = _default;
});