define("ember-bootstrap/templates/components/bs-carousel/slide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bLA+SLwF",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"carousel-item\",\" \",[29],\" \",[30,[36,0],[[32,0,[\"active\"]],\"active\"],null],\" \",[30,[36,0],[[32,0,[\"left\"]],\"carousel-item-left\"],null],\" \",[29],\" \",[30,[36,0],[[32,0,[\"next\"]],\"carousel-item-next\"],null],\" \",[29],\" \",[30,[36,0],[[32,0,[\"prev\"]],\"carousel-item-prev\"],null],\" \",[29],\" \",[30,[36,0],[[32,0,[\"right\"]],\"carousel-item-right\"],null],\" \",[29]]]],[17,1],[4,[38,1],[[32,0],\"_element\"],null],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"ref\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-carousel/slide.hbs"
    }
  });

  _exports.default = _default;
});