define("ember-popper/templates/components/ember-popper-targeting-parent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RijStZ6E",
    "block": "{\"symbols\":[\"@id\",\"@class\",\"@ariaRole\",\"&attrs\",\"&default\"],\"statements\":[[1,[30,[36,4],[[32,0,[\"_parentFinder\"]]],null]],[2,\"\\n\\n\"],[6,[37,5],[[32,0,[\"renderInPlace\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[16,1,[32,1]],[16,0,[32,2]],[16,\"hidden\",[32,0,[\"hidden\"]]],[16,\"role\",[32,3]],[17,4],[4,[38,0],[[32,0],\"_popperElement\"],null],[12],[2,\"\\n    \"],[18,5,[[30,[36,2],null,[[\"disableEventListeners\",\"enableEventListeners\",\"scheduleUpdate\",\"update\"],[[30,[36,1],[[32,0],\"disableEventListeners\"],null],[30,[36,1],[[32,0],\"enableEventListeners\"],null],[30,[36,1],[[32,0],\"scheduleUpdate\"],null],[30,[36,1],[[32,0],\"update\"],null]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[32,0,[\"_popperContainer\"]]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",null]],[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[16,1,[32,1]],[16,0,[32,2]],[16,\"hidden\",[32,0,[\"hidden\"]]],[16,\"role\",[32,3]],[17,4],[4,[38,0],[[32,0],\"_popperElement\"],null],[12],[2,\"\\n      \"],[18,5,[[30,[36,2],null,[[\"disableEventListeners\",\"enableEventListeners\",\"scheduleUpdate\",\"update\"],[[30,[36,1],[[32,0],\"disableEventListeners\"],null],[30,[36,1],[[32,0],\"enableEventListeners\"],null],[30,[36,1],[[32,0],\"scheduleUpdate\"],null],[30,[36,1],[[32,0],\"update\"],null]]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"ref\",\"action\",\"hash\",\"in-element\",\"unbound\",\"if\"]}",
    "meta": {
      "moduleName": "ember-popper/templates/components/ember-popper-targeting-parent.hbs"
    }
  });

  _exports.default = _default;
});