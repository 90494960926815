define("ember-bootstrap/templates/components/bs-modal/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aSYqXoVb",
    "block": "{\"symbols\":[\"@onClose\",\"@submitTitle\",\"@onSubmit\",\"&default\",\"&attrs\"],\"statements\":[[11,\"form\"],[24,0,\"modal-footer\"],[17,5],[4,[38,2],[\"submit\",[32,0,[\"handleSubmit\"]]],null],[12],[2,\"\\n\"],[6,[37,1],[[27,[32,4]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,4,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,0,[\"hasSubmitButton\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[6,[37,0],[[32,0,[\"buttonComponent\"]]],[[\"onClick\"],[[32,1]]],[[\"default\"],[{\"statements\":[[1,[32,0,[\"closeTitle\"]]]],\"parameters\":[]}]]],[2,\"\\n      \"],[6,[37,0],[[32,0,[\"buttonComponent\"]]],[[\"type\",\"onClick\",\"_disabled\"],[[32,0,[\"submitButtonType\"]],[32,3],[32,0,[\"submitDisabled\"]]]],[[\"default\"],[{\"statements\":[[1,[32,2]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[6,[37,0],[[32,0,[\"buttonComponent\"]]],[[\"type\",\"onClick\"],[\"primary\",[32,1]]],[[\"default\"],[{\"statements\":[[1,[32,0,[\"closeTitle\"]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"if\",\"on\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-modal/footer.hbs"
    }
  });

  _exports.default = _default;
});