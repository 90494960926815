define("ember-bootstrap/templates/components/bs-tab/pane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S1Ojhkky",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"tab-pane \",[30,[36,0],[[32,0,[\"showContent\"]],\"show\"],null],\" \",[29],\" \",[30,[36,0],[[32,0,[\"active\"]],\"active\"],null],\" \",[30,[36,0],[[32,0,[\"usesTransition\"]],\"fade\"],null]]]],[24,\"role\",\"tabpanel\"],[17,1],[4,[38,1],[[32,0],\"_element\"],null],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"ref\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-tab/pane.hbs"
    }
  });

  _exports.default = _default;
});