define("ember-bootstrap/templates/components/bs-modal-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pSj7ArDq",
    "block": "{\"symbols\":[\"modal\",\"&attrs\",\"@title\",\"&default\",\"@submitTitle\"],\"statements\":[[8,\"bs-modal\",[[17,2]],[[\"@open\",\"@fade\",\"@backdrop\",\"@keyboard\",\"@position\",\"@scrollable\",\"@size\",\"@backdropClose\",\"@renderInPlace\",\"@transitionDuration\",\"@backdropTransitionDuration\",\"@onSubmit\",\"@onHide\",\"@onHidden\",\"@onShow\",\"@onShown\"],[[32,0,[\"open\"]],[32,0,[\"fade\"]],[32,0,[\"backdrop\"]],[32,0,[\"keyboard\"]],[32,0,[\"position\"]],[32,0,[\"scrollable\"]],[32,0,[\"size\"]],[32,0,[\"backdropClose\"]],[32,0,[\"renderInPlace\"]],[32,0,[\"transitionDuration\"]],[32,0,[\"backdropTransitionDuration\"]],[32,0,[\"onSubmit\"]],[32,0,[\"onHide\"]],[32,0,[\"onHidden\"]],[32,0,[\"onShow\"]],[32,0,[\"onShown\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[\"@title\",\"@closeButton\"],[[32,3],[32,0,[\"closeButton\"]]]],null],[2,\"\\n  \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,4,[[30,[36,0],null,[[\"close\",\"submit\"],[[32,1,[\"close\"]],[32,1,[\"submit\"]]]]]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"footer\"]],[],[[\"@closeTitle\",\"@submitTitle\",\"@submitButtonType\"],[[32,0,[\"closeTitle\"]],[32,5],[32,0,[\"submitButtonType\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-modal-simple.hbs"
    }
  });

  _exports.default = _default;
});