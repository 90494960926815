define("ember-bootstrap/templates/components/bs-form/element/help-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ji7UUSWW",
    "block": "{\"symbols\":[\"@id\",\"&attrs\",\"@text\"],\"statements\":[[11,\"div\"],[16,1,[32,1]],[16,0,\"form-text\"],[17,2],[12],[2,\"\\n  \"],[1,[32,3]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/help-text.hbs"
    }
  });

  _exports.default = _default;
});