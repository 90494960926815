define("ember-bootstrap/templates/components/bs-form/element/layout/horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MYJEa+RI",
    "block": "{\"symbols\":[\"&default\",\"@errorsComponent\",\"@helpTextComponent\",\"@horizontalLabelGridClass\",\"@labelComponent\"],\"statements\":[[6,[37,1],[[32,0,[\"hasLabel\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[[32,5]],[[\"labelClass\"],[[32,4]]]]],[2,\"\\n  \"],[10,\"div\"],[15,0,[32,0,[\"horizontalInputGridClass\"]]],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n\"],[2,\"    \"],[1,[30,[36,0],[[32,2]],null]],[2,\"\\n    \"],[1,[30,[36,0],[[32,3]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[[32,0,[\"horizontalInputGridClass\"]],\" \",[32,0,[\"horizontalInputOffsetGridClass\"]]]]],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n\"],[2,\"    \"],[1,[30,[36,0],[[32,2]],null]],[2,\"\\n    \"],[1,[30,[36,0],[[32,3]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"if\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/layout/horizontal.hbs"
    }
  });

  _exports.default = _default;
});