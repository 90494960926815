define("ember-bootstrap/templates/components/bs-modal/dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rA/XHnb/",
    "block": "{\"symbols\":[\"@inDom\",\"@showModal\",\"@fade\",\"&attrs\",\"@paddingRight\",\"@paddingLeft\",\"@scrollable\",\"@centered\",\"&default\"],\"statements\":[[11,\"div\"],[24,\"role\",\"dialog\"],[24,\"tabindex\",\"-1\"],[16,\"aria-labelledby\",[32,0,[\"ariaLabelledby\"]]],[16,0,[31,[\"modal \",[30,[36,0],[[32,3],\"fade\"],null],\" \",[30,[36,0],[[32,2],\"show\"],null],\" \",[30,[36,0],[[32,1],\"d-block\"],null]]]],[17,4],[4,[38,1],[\"keydown\",[32,0,[\"handleKeyDown\"]]],null],[4,[38,1],[\"mousedown\",[32,0,[\"handleMouseDown\"]]],null],[4,[38,1],[\"mouseup\",[32,0,[\"handleMouseUp\"]]],null],[4,[38,1],[\"click\",[32,0,[\"handleClick\"]]],null],[4,[38,3],null,[[\"paddingLeft\",\"paddingRight\",\"display\"],[[30,[36,2],[[32,6],\"px\"],null],[30,[36,2],[[32,5],\"px\"],null],[30,[36,0],[[32,1],\"block\",\"\"],null]]]],[4,[38,4],[[32,0],\"_element\"],null],[4,[38,5],[[32,0,[\"getOrSetTitleId\"]]],null],[4,[38,5],[[32,0,[\"setInitialFocus\"]]],null],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"modal-dialog \",[32,0,[\"sizeClass\"]],\" \",[30,[36,0],[[32,8],\"modal-dialog-centered\"],null],\" \",[30,[36,0],[[32,7],\"modal-dialog-scrollable\"],null]]]],[14,\"role\",\"document\"],[12],[2,\"\\n    \"],[11,\"div\"],[24,0,\"modal-content\"],[4,[38,7],null,[[\"shouldSelfFocus\",\"focusTrapOptions\"],[true,[30,[36,6],null,[[\"clickOutsideDeactivates\"],[true]]]]]],[12],[2,\"\\n      \"],[18,9,null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"on\",\"concat\",\"style\",\"ref\",\"did-insert\",\"hash\",\"focus-trap\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-modal/dialog.hbs"
    }
  });

  _exports.default = _default;
});