define("ember-bootstrap/templates/components/bs-progress/bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MGkzFDti",
    "block": "{\"symbols\":[\"&default\",\"&attrs\"],\"statements\":[[11,\"div\"],[24,\"role\",\"progressbar\"],[16,\"aria-valuenow\",[32,0,[\"value\"]]],[16,\"aria-valuemin\",[32,0,[\"minValue\"]]],[16,\"aria-valuemax\",[32,0,[\"maxValue\"]]],[16,0,[31,[\"progress-bar \",[30,[36,0],[[32,0,[\"striped\"]],\"progress-bar-striped\"],null],\" \",[32,0,[\"typeClass\"]],\" \",[30,[36,0],[[32,0,[\"animate\"]],\"progress-bar-animated\"],null]]]],[17,2],[4,[38,1],null,[[\"width\"],[[32,0,[\"percentStyleValue\"]]]]],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"showLabel\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,0],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,1,[[32,0,[\"percentRounded\"]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[32,0,[\"percentRounded\"]]],[2,\"%\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"sr-only\"],[12],[18,1,[[32,0,[\"percentRounded\"]]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"sr-only\"],[12],[1,[32,0,[\"percentRounded\"]]],[2,\"%\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"style\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-progress/bar.hbs"
    }
  });

  _exports.default = _default;
});