define("ember-bootstrap/templates/components/bs-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "woXZU8cE",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"form\"],[24,\"role\",\"form\"],[16,\"novalidate\",[32,0,[\"hasValidator\"]]],[16,0,[32,0,[\"layoutClass\"]]],[17,1],[4,[38,0],[[32,0],\"_element\"],null],[4,[38,1],[\"keypress\",[32,0,[\"handleKeyPress\"]]],null],[4,[38,1],[\"submit\",[32,0,[\"handleSubmit\"]]],null],[12],[2,\"\\n  \"],[18,2,[[30,[36,3],null,[[\"element\",\"group\",\"isSubmitting\",\"isSubmitted\",\"isRejected\",\"resetSubmissionState\",\"submit\"],[[30,[36,2],[[32,0,[\"elementComponent\"]]],[[\"model\",\"formLayout\",\"horizontalLabelGridClass\",\"showAllValidations\",\"_disabled\",\"_readonly\",\"onChange\",\"_onChange\"],[[32,0,[\"model\"]],[32,0,[\"formLayout\"]],[32,0,[\"horizontalLabelGridClass\"]],[32,0,[\"showAllValidations\"]],[32,0,[\"disabled\"]],[32,0,[\"readonly\"]],[32,0,[\"elementChanged\"]],[32,0,[\"resetSubmissionState\"]]]]],[30,[36,2],[[32,0,[\"groupComponent\"]]],[[\"formLayout\"],[[32,0,[\"formLayout\"]]]]],[32,0,[\"isSubmitting\"]],[32,0,[\"isSubmitted\"]],[32,0,[\"isRejected\"]],[32,0,[\"resetSubmissionState\"]],[32,0,[\"doSubmit\"]]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"ref\",\"on\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form.hbs"
    }
  });

  _exports.default = _default;
});