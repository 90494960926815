define("ember-bootstrap/templates/components/bs-form/element/control/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dnJ75KD4",
    "block": "{\"symbols\":[\"@id\",\"&attrs\"],\"statements\":[[11,\"input\"],[16,1,[32,1]],[16,\"disabled\",[32,0,[\"disabled\"]]],[16,\"readonly\",[32,0,[\"readonly\"]]],[16,\"aria-describedby\",[32,0,[\"ariaDescribedBy\"]]],[16,\"checked\",[32,0,[\"value\"]]],[16,0,[31,[\"form-check-input\",\" \",[32,0,[\"formValidationClass\"]]]]],[17,2],[24,4,\"checkbox\"],[4,[38,0],[\"click\",[32,0,[\"handleClick\"]]],null],[12],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/control/checkbox.hbs"
    }
  });

  _exports.default = _default;
});