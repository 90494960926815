define("ember-bootstrap/templates/components/bs-navbar/content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C5pcYn0U",
    "block": "{\"symbols\":[\"@collapsed\",\"@onHidden\",\"@onShown\",\"&attrs\",\"&default\"],\"statements\":[[8,\"bs-collapse\",[[24,0,\"navbar-collapse\"],[17,4]],[[\"@collapsed\",\"@onHidden\",\"@onShown\"],[[32,1],[32,2],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,5,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-navbar/content.hbs"
    }
  });

  _exports.default = _default;
});