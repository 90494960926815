define("ember-bootstrap/templates/components/bs-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tQfV2g36",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"button\"],[16,\"disabled\",[32,0,[\"__disabled\"]]],[16,0,[31,[\"btn \",[30,[36,0],[[32,0,[\"active\"]],\"active\"],null],\" \",[30,[36,0],[[32,0,[\"block\"]],\"btn-block\"],null],\" \",[32,0,[\"sizeClass\"]],\" \",[32,0,[\"typeClass\"]]]]],[17,1],[16,4,[32,0,[\"buttonType\"]]],[4,[38,1],[\"click\",[32,0,[\"handleClick\"]]],null],[12],[2,\"\\n  \"],[6,[37,0],[[32,0,[\"icon\"]]],null,[[\"default\"],[{\"statements\":[[10,\"i\"],[15,0,[32,0,[\"icon\"]]],[12],[13],[2,\" \"]],\"parameters\":[]}]]],[1,[32,0,[\"text\"]]],[18,2,[[30,[36,2],null,[[\"isFulfilled\",\"isPending\",\"isRejected\",\"isSettled\"],[[32,0,[\"isFulfilled\"]],[32,0,[\"isPending\"]],[32,0,[\"isRejected\"]],[32,0,[\"isSettled\"]]]]]]],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"on\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-button.hbs"
    }
  });

  _exports.default = _default;
});