define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en", {
    "address": {
      "address": "Street + house number",
      "city": "Town",
      "postalcode": "Postal code",
      "title": "Address"
    },
    "association": {
      "index": {
        "continue": "Continue",
        "continue_without_training": "Continue without open practice sign up",
        "description": "Did you sign up for the open practices earlier? Then your final registration could be done a lot quicker! Enter your phone number and date of birth, then you won't need to enter all the information again.",
        "error": {
          "duplicate": "It seems you have already completed your registration. If you believe this to be an error, please click the \"Continue without open practice sign up\" link.",
          "notfound": "We couldn't find a registration for the open practices based on the information you entered. Please check that the information is correct or register via the \"Continue without open practice sign up\" link."
        }
      },
      "required_fields": "Please fill in all the required fields.",
      "title": "Register for membership"
    },
    "availability": {
      "attend": "Please click the open practices you want to attend.",
      "committees": {
        "interested": "Interested in committees?",
        "readmore": "Read more about committees at Erasmus Volley",
        "title": "Committees",
        "yes": "Yes, I am interested in joining a committee!"
      },
      "days": {
        "description": "Please indicate which days of the week you are available during the season.",
        "monday": "Monday",
        "thursday": "Thursday",
        "title": "Weekdays",
        "wednesday": "Wednesday"
      },
      "season": {
        "available": "I am available the entire season",
        "title": "Season",
        "unavailable": "I am away for part of the season (eg. due to exchange)",
        "unavailable_description": "Please elaborate when you are absent",
        "unavailable_title": "Absence"
      },
      "title": "Availability"
    },
    "comments": {
      "description": "Please let us know here if you have any additional questions or comments. We'll get back to you as soon as possible!",
      "title": "Comments and questions"
    },
    "continue": "Continue",
    "date": {
      "day": "- day -",
      "month": "- month -",
      "year": "- year -"
    },
    "dob": "Date of birth",
    "duplicate_openpractice": {
      "continue_membership": "Register for membership instead",
      "continue_openpractice": "It is correct, I am trying to sign up for an open practice",
      "description": "It seems you have already signed up for an open practice in the past. If you're trying to register for membership to the association, please fill out the information below to continue.",
      "or": "or",
      "possible": "It seems you have already signed up for an open practice in the past. Click here if you're trying to register for membership to the association.",
      "title": "Is this right?"
    },
    "email": "Email",
    "experience": {
      "detail": "Please elaborate your level of experience",
      "detail_optional": "Optionally elaborate your level of experience",
      "level": {
        "abroad": "Experience abroad",
        "jeugd": "Jeugd (Dutch competition)",
        "noidea": "No idea",
        "none": "No prior experience",
        "recreanten": "Recreanten (Dutch competition)",
        "senioren": "Senioren (Dutch competition)",
        "title": "Level"
      },
      "positions": {
        "no_preference": "No preference",
        "none": "I have never played positions",
        "preferred_title": "Preferred position",
        "title": "Positions"
      },
      "referee": {
        "no_licence": "- no (Dutch) licence -",
        "title": "Referee licence"
      },
      "title": "Experience",
      "years": {
        "description": "How many years of volleyball experience do you have?",
        "option": "{years, plural, =10 {10 or more years} =1 {1 year} other {# years}}",
        "title": "Years"
      },
      "yearsago": {
        "description": "When was the last time you played volleyball?",
        "option": "{years, plural, =10 {10 or more years} =1 {1 year} other {# years}} ago",
        "title": "Last played"
      }
    },
    "gender": {
      "f": "Female",
      "m": "Male",
      "title": "Gender"
    },
    "height": {
      "in_cm": "in centimetres",
      "title": "Height"
    },
    "loading": "Loading...",
    "membership": {
      "shirt": {
        "description": "A match shirt costs {price}.",
        "size": {
          "title": "Shirt size"
        },
        "title": "Shirt",
        "type": {
          "men": "Men/Unisex",
          "title": "Shirt type",
          "women": "Women"
        }
      },
      "sportspass": {
        "description": "Every member is required to have an Erasmus Sport pass. This pass is necessary to make use of the facilities.",
        "other": {
          "title": "Through which association do you have an Erasmus Sport pass?"
        },
        "source": {
          "erasmussport": "No, I already have a pass via Erasmus Sport",
          "erasmusvolley": "Yes, I would like a pass via Erasmus Volley ({price})",
          "other": "No, I already have a pass via another association",
          "title": "Do you want to buy an Erasmus Sport pass via Erasmus Volley?"
        },
        "title": "Erasmus Sport pass"
      },
      "title": "Membership",
      "type": {
        "contribution": {
          "full": "Contribution:",
          "half": "Half year:"
        },
        "description": "",
        "halfyear": "The half year contribution will be applied if you're absent for at least 3 months.",
        "recreatief": {
          "description": "If you want to play in a recreational team, experience is not required. Matches are always on weekdays, the home matches will be on Thursday evening and you won't have practice when you have a match.",
          "title": "Recreational"
        },
        "regulier": {
          "description": "If you want to play in a regular team, volleyball experience is a must. The home matches will be on Thursday evening and away matches are usually on Friday evening or Saturday.",
          "title": "Competitive"
        },
        "title": "Type",
        "training": {
          "description": "You can choose this option if you want to join a competitive men's or ladies team but not play any matches.",
          "title": "Training member"
        }
      }
    },
    "name": {
      "firstname": "Given name",
      "infix": "Infix (de, van, etc.)",
      "initials": "Initials",
      "lastname": "Family name",
      "title": "Name"
    },
    "nationality": "Nationality",
    "openpractice": {
      "none": {
        "contact": "Do you have questions? Please contact the Technical Committee.",
        "message": "There are currently no open practices to sign up for."
      },
      "required_fields": "Please fill in all the required fields.",
      "title": "Sign up for open practices"
    },
    "payments": {
      "bic": {
        "description": "Also called SWIFT code, you can usually find this on your bank's website.",
        "title": "BIC"
      },
      "description": "The contribution will be collected by direct debit around the end of October.",
      "holder": {
        "description": "Your name as it is listed on your bank account.",
        "title": "Name account holder"
      },
      "iban": {
        "description": "Your account number",
        "none": "I don't have a bank account (yet) in an EU/SEPA country",
        "sample": "e.g. {iban}",
        "title": "IBAN"
      },
      "title": "Payment"
    },
    "phone": {
      "title": "Phone",
      "whatsapp": "Working WhatsApp number"
    },
    "photo": {
      "current": "Current photo",
      "description": "A photo is a required part of your registration. Your photo will only be used during the open practices for us to recognise you. After the open practices your photo will be deleted.",
      "retakephoto": "Retake photo",
      "takephoto": "Take photo",
      "title": "Photo",
      "upload": "Upload",
      "usephoto": "Use photo",
      "webcam": "Webcam"
    },
    "pleaseselect": "- choose option -",
    "positions": {
      "diagonal": "Diagonal",
      "libero": "Libero",
      "middle": "Middle",
      "outside": "Outside",
      "setter": "Setter"
    },
    "questions": {
      "title": "Final questions"
    },
    "send": "Submit",
    "sending": "Submitting...",
    "study": {
      "description": "What do you study?",
      "firstyear": {
        "description": "This is my first year studying at the Erasmus University",
        "title": "First year?"
      },
      "institution": {
        "eur": "Erasmus University",
        "hro": "Hogeschool Rotterdam",
        "none": "I don't study",
        "other": "Other...",
        "other_desc": "Please enter the name of your university",
        "title": "Institution"
      },
      "studentnumber": {
        "title": "Student number"
      },
      "title": "Study",
      "type": {
        "bachelor": "Bachelor",
        "master": "Master",
        "other": "Other",
        "title": "Kind of study"
      },
      "upcoming_season": "Describe the situation as it will be during the {season} season.",
      "year": {
        "description": "What year are you currently in?",
        "title": "Year"
      }
    },
    "terms": {
      "label": "I agree to the terms of membership and the publication of visual materials outlined therein.",
      "title": "Terms of membership"
    },
    "welcome": {
      "openpractice": {
        "signup": "Sign up",
        "title": "Open practices"
      },
      "questions": {
        "contact": "Do you have questions? Please contact the Technical Committee.",
        "mail": "Email us",
        "title": "Questions?"
      }
    }
  }], ["nl", {
    "address": {
      "address": "Straat + huisnr",
      "city": "Woonplaats",
      "postalcode": "Postcode",
      "title": "Adres"
    },
    "association": {
      "index": {
        "continue": "Doorgaan",
        "continue_without_training": "Doorgaan zonder aanmelding voor de open trainingen",
        "description": "Als je je al hebt aangemeld voor de open trainingen dan kan je inschrijving een stukje sneller. Vul hier je telefoonnummer en geboortedatum in, dan hoef je de informatie die je toen hebt ingevuld niet opnieuw in te vullen!",
        "error": {
          "duplicate": "Het lijkt erop dat je je registratie al hebt voltooid. Als dit niet het geval is, klik dan op de \"Doorgaan zonder aanmelding voor de open trainingen\" link.",
          "notfound": "We hebben geen open training inschrijving kunnen vinden op basis van deze gegevens. Controleer of de gegevens kloppen of schrijf je in via de \"Doorgaan zonder aanmelding voor de open trainingen\" link."
        }
      },
      "required_fields": "Vul alle verplichte velden in.",
      "title": "Inschrijven voor lidmaatschap"
    },
    "availability": {
      "attend": "Klik op de open trainingen waar je aan wilt deelnemen.",
      "committees": {
        "interested": "Geïntereseerd in commissies?",
        "readmore": "Lees meer over commissies bij Erasmus Volley",
        "title": "Commissies",
        "yes": "Ja, ik zou wel interesse hebben in een commissie!"
      },
      "days": {
        "description": "Vink de dagen aan dat je gedurende het seizoen beschikbaar bent.",
        "monday": "Maandag",
        "thursday": "Donderdag",
        "title": "Dagen",
        "wednesday": "Woensdag"
      },
      "season": {
        "available": "Ik ben het hele seizoen aanwezig",
        "title": "Seizoen",
        "unavailable": "Ik ben voor een deel van het seizoen weg (bijv. door exchange)",
        "unavailable_description": "Wanneer ben je niet beschikbaar om te spelen?",
        "unavailable_title": "Afwezigheid"
      },
      "title": "Beschikbaarheid"
    },
    "comments": {
      "description": "Laat ons hier weten als je nog vragen of opmerkingen hebt. We zullen deze zo snel mogelijk beantwoorden!",
      "title": "Vragen en opmerkingen"
    },
    "continue": "Doorgaan",
    "date": {
      "day": "- dag -",
      "month": "- maand -",
      "year": "- jaar -"
    },
    "dob": "Geboortedag",
    "duplicate_openpractice": {
      "continue_membership": "Door naar inschrijven voor lidmaatschap",
      "continue_openpractice": "Dit klopt, ik probeer mij aan te melden voor een open training",
      "description": "Het lijkt erop dat je je al hebt aangemeld voor de open trainingen. Als je je probeert in te schrijven voor lidmaatschap, vul dan het onderstaande formulier in om door te gaan!",
      "or": "of",
      "possible": "Het lijkt erop dat je je al hebt aangemeld voor de open trainingen. Klik hier als je je probeert in te schrijven voor lidmaatschap.",
      "title": "Gaat dit goed?"
    },
    "email": "Email",
    "experience": {
      "detail": "Omschrijf je ervaring",
      "detail_optional": "Omschrijf eventueel je ervaring",
      "level": {
        "abroad": "In het buitenland",
        "jeugd": "Jeugd",
        "noidea": "Geen idee",
        "none": "Geen ervaring",
        "recreanten": "Recreanten",
        "senioren": "Senioren",
        "title": "Niveau"
      },
      "positions": {
        "no_preference": "Geen voorkeur",
        "none": "Ik heb nooit posities gespeeld",
        "preferred_title": "Voorkeurs positie",
        "title": "Posities"
      },
      "referee": {
        "no_licence": "- geen Nevobo licentie -",
        "title": "Scheidslicentie"
      },
      "title": "Ervaring",
      "years": {
        "description": "Hoeveel jaar volleybal ervaring heb je?",
        "option": "{years, plural, =10 {10 of meer jaar} other {# jaar}}",
        "title": "Jaren"
      },
      "yearsago": {
        "description": "Wanneer heb je voor het laatst (competitief) volleybal gespeeld?",
        "option": "{years, plural, =10 {10 or meer jaar} other {# jaar}} geleden",
        "title": "Laatst gespeeld"
      }
    },
    "gender": {
      "f": "Vrouw",
      "m": "Man",
      "title": "Geslacht"
    },
    "height": {
      "in_cm": "in centimeters",
      "title": "Lengte"
    },
    "loading": "Laden...",
    "membership": {
      "shirt": {
        "description": "Een wedstrijdshirt kost {price}.",
        "size": {
          "title": "Shirt maat"
        },
        "title": "Shirt",
        "type": {
          "men": "Heren/Unisex",
          "title": "Shirt type",
          "women": "Dames"
        }
      },
      "sportspass": {
        "description": "Ieder lid is verplicht om een Erasmus Sport pas te hebben. Deze pas heb je nodig om gebruik te kunnen maken van de faciliteiten.",
        "other": {
          "title": "Via welke vereniging heb je een Erasmus Sport pas?"
        },
        "source": {
          "erasmussport": "Nee, ik heb zelf een sportpas gekocht bij Erasmus Sport",
          "erasmusvolley": "Ja, ik wil een sportpas via Erasmus Volley ({price})",
          "other": "Nee, ik heb al een sportpas via een andere vereniging",
          "title": "Wil je een Erasmus Sport pas kopen via Erasmus Volley?"
        },
        "title": "Erasmus Sport pas"
      },
      "title": "Lidmaatschap",
      "type": {
        "contribution": {
          "full": "Contributie:",
          "half": "Half jaar:"
        },
        "description": "",
        "halfyear": "De halfjaarscontributie wordt toegepast indien je minstens 3 maanden afwezig bent.",
        "recreatief": {
          "description": "Als je recreatief wilt spelen is volleybalervaring dus geen vereiste. Wedstrijden zijn altijd doordeweeks; de thuiswedstrijden vinden altijd plaats op de donderdagavond. Als je een thuiswedstrijd hebt, vervalt de training.",
          "title": "Recreatief"
        },
        "regulier": {
          "description": "Als je regulier wilt spelen is volleybalervaring een vereiste. De thuiswedstrijden vinden plaats op de donderdagavond, en uitwedstrijden zijn meestal op een vrijdagavond of op een zaterdag.",
          "title": "Regulier"
        },
        "title": "Type",
        "training": {
          "description": "Deze optie kun je kiezen wanneer je binnen een regulier heren- of damesteam wilt volleyballen maar geen wedstrijden wilt spelen.",
          "title": "Trainingslid"
        }
      }
    },
    "name": {
      "firstname": "Voornaam",
      "infix": "Tussenvoegsel (de, van, enz.)",
      "initials": "Initialen",
      "lastname": "Achternaam",
      "title": "Naam"
    },
    "nationality": "Nationaliteit",
    "openpractice": {
      "none": {
        "contact": "Heb je vragen? Neem dan contact op met de Technische Commissie.",
        "message": "Er zijn momenteel geen open trainingen waar je voor kan aanmelden."
      },
      "required_fields": "Vul alle verplichte velden in.",
      "title": "Aanmelden voor open trainingen"
    },
    "payments": {
      "bic": {
        "description": "Ookwel SWIFT code genoemd, meestal te vinden op de website van je bank.",
        "title": "BIC"
      },
      "description": "De contributie wordt rond eind oktober afgeschreven per automatische incasso.",
      "holder": {
        "description": "Jouw naam zoals die op je rekening is vermeld.",
        "title": "Rekeninghouder"
      },
      "iban": {
        "description": "Je rekeningnummer",
        "none": "Ik heb (nog) geen bankrekening in een EU/SEPA land",
        "sample": "bijv. {iban}",
        "title": "IBAN"
      },
      "title": "Betalingen"
    },
    "phone": {
      "title": "Mobiel",
      "whatsapp": "Werkend WhatsApp nummer"
    },
    "photo": {
      "current": "Huidige foto",
      "description": "Een foto is een verplicht onderdeel van de inschrijving. Je foto zal alleen gebruikt worden tijdens de open trainingen zodat wij je kunnen herkennen. Daarna wordt je foto verwijderd.",
      "retakephoto": "Opnieuw maken",
      "takephoto": "Neem foto",
      "title": "Foto",
      "upload": "Uploaden",
      "usephoto": "Foto gebruiken",
      "webcam": "Webcam"
    },
    "pleaseselect": "- kies optie -",
    "positions": {
      "diagonal": "Diagonaal",
      "libero": "Libero",
      "middle": "Midden",
      "outside": "Buiten",
      "setter": "Spelverdeler"
    },
    "questions": {
      "title": "Laatste vragen"
    },
    "send": "Versturen",
    "sending": "Bezig met versturen...",
    "study": {
      "description": "Wat studeer je?",
      "firstyear": {
        "description": "Dit is mijn eerste jaar aan de Erasmus Universiteit",
        "title": "Eerste jaar?"
      },
      "institution": {
        "eur": "Erasmus Universiteit",
        "hro": "Hogeschool Rotterdam",
        "none": "Ik studeer niet",
        "other": "Anders...",
        "other_desc": "Vul de naam in van je onderwijsinstelling",
        "title": "Instelling"
      },
      "studentnumber": {
        "title": "Studentnummer"
      },
      "title": "Studie",
      "type": {
        "bachelor": "Bachelor",
        "master": "Master",
        "other": "Anders",
        "title": "Soort studie"
      },
      "upcoming_season": "Beschrijf de situatie zoals deze in het seizoen {season} zal zijn.",
      "year": {
        "description": "In welk jaar zit je momenteel?",
        "title": "Jaar"
      }
    },
    "terms": {
      "label": "Ik ga akkoord met de lidmaatschapsvoorwaarden en de publicatie van beeldmateriaal zoals daarin beschreven.",
      "title": "Voorwaarden lidmaatschap"
    },
    "welcome": {
      "openpractice": {
        "signup": "Aanmelden",
        "title": "Open trainingen"
      },
      "questions": {
        "contact": "Heb je vragen? Neem dan contact op met de Technische Commissie.",
        "mail": "Mail ons",
        "title": "Vragen?"
      }
    }
  }]];
  _exports.default = _default;
});