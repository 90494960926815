define("ember-bootstrap/templates/components/bs-popover/element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gSUwWbLS",
    "block": "{\"symbols\":[\"@title\",\"@destinationElement\",\"@id\",\"@class\",\"&attrs\",\"&default\"],\"statements\":[[8,\"ember-popper\",[[17,5]],[[\"@ariaRole\",\"@placement\",\"@renderInPlace\",\"@popperTarget\",\"@modifiers\",\"@popperContainer\",\"@onCreate\",\"@onUpdate\",\"@id\",\"@class\"],[[32,0,[\"ariaRole\"]],[32,0,[\"placement\"]],[32,0,[\"renderInPlace\"]],[32,0,[\"popperTarget\"]],[32,0,[\"popperModifiers\"]],[32,2],[32,0,[\"updatePlacement\"]],[32,0,[\"updatePlacement\"]],[32,3],[31,[\"popover \",[32,4],\" \",[30,[36,0],[[32,0,[\"fade\"]],\"fade\"],null],\" \",[30,[36,1],[\"bs-popover-\",[32,0,[\"actualPlacement\"]]],null],\" \",[29],\" \",[30,[36,0],[[32,0,[\"showHelp\"]],\"show\"],null],\" \",[29],\" \",[29]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[15,0,[32,0,[\"arrowClass\"]]],[12],[13],[2,\"\\n\"],[6,[37,0],[[32,0,[\"hasTitle\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h3\"],[15,0,[31,[\"popover-header\",[29]]]],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[15,0,[31,[\"popover-body\",[29]]]],[12],[18,6,null],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-popover/element.hbs"
    }
  });

  _exports.default = _default;
});