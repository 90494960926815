define("ember-bootstrap/templates/components/bs-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OdehcLhw",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"ul\"],[16,0,[31,[\"nav \",[32,0,[\"typeClass\"]],\" \",[32,0,[\"additionalClass\"]],\" \",[30,[36,0],[[32,0,[\"justified\"]],\"nav-justified\"],null],\" \",[30,[36,0],[[32,0,[\"stacked\"]],\"flex-column\"],null],\" \",[30,[36,0],[[32,0,[\"fill\"]],\"nav-fill\"],null]]]],[17,1],[12],[2,\"\\n  \"],[18,2,[[30,[36,2],null,[[\"item\",\"link-to\",\"linkTo\",\"dropdown\"],[[30,[36,1],[[32,0,[\"itemComponent\"]]],null],[30,[36,1],[[32,0,[\"linkToComponent\"]]],null],[30,[36,1],[[32,0,[\"linkToComponent\"]]],null],[30,[36,1],[[32,0,[\"dropdownComponent\"]]],[[\"inNav\",\"htmlTag\"],[true,\"li\"]]]]]]]],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-nav.hbs"
    }
  });

  _exports.default = _default;
});