define("ember-bootstrap/templates/components/bs-form/element/label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W/B8a5TT",
    "block": "{\"symbols\":[\"&default\",\"@formElementId\",\"@label\"],\"statements\":[[2,\"  \"],[10,\"label\"],[15,0,[31,[[30,[36,0],[[32,0,[\"invisibleLabel\"]],\"sr-only\"],null],\" \",[32,0,[\"labelClass\"]],\" \",[30,[36,0],[[32,0,[\"isHorizontalAndNotCheckbox\"]],\"col-form-label\"],null],\" \",[30,[36,0],[[32,0,[\"isCheckbox\"]],\"form-check-label\"],null],\" \",[32,0,[\"sizeClass\"]]]]],[15,\"for\",[32,2]],[12],[2,\"\\n\"],[6,[37,0],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[1,[32,3]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/label.hbs"
    }
  });

  _exports.default = _default;
});