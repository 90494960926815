define("ember-bootstrap/templates/components/bs-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l79TYglW",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"progress\"],[17,1],[12],[2,\"\\n  \"],[18,2,[[30,[36,1],null,[[\"bar\"],[[30,[36,0],[[32,0,[\"progressBarComponent\"]]],null]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-progress.hbs"
    }
  });

  _exports.default = _default;
});