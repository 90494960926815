define("ember-bootstrap/templates/components/bs-nav/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+sSea3S3",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"li\"],[16,0,[31,[\"nav-item\",\" \",[30,[36,0],[[32,0,[\"disabled\"]],\"disabled\"],null],\" \",[30,[36,0],[[32,0,[\"active\"]],\"active\"],null]]]],[17,1],[4,[38,1],[\"click\",[32,0,[\"handleClick\"]]],null],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-nav/item.hbs"
    }
  });

  _exports.default = _default;
});