define("ember-bootstrap/templates/components/bs-collapse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JHtwZSaO",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,0],[[32,0,[\"collapse\"]],\"collapse\"],null],\" \",[30,[36,0],[[32,0,[\"collapsing\"]],\"collapsing\"],null],\" \",[30,[36,0],[[32,0,[\"showContent\"]],\"show\"],null]]]],[17,1],[4,[38,1],[[32,0],\"_element\"],null],[4,[38,2],[[32,0,[\"cssStyle\"]]],null],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"ref\",\"style\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-collapse.hbs"
    }
  });

  _exports.default = _default;
});