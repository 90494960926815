define("ember-bootstrap/templates/components/bs-navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9SqOYu4y",
    "block": "{\"symbols\":[\"yieldedHash\",\"&default\",\"&attrs\"],\"statements\":[[6,[37,3],[[30,[36,2],null,[[\"toggle\",\"content\",\"nav\",\"collapse\",\"expand\",\"toggleNavbar\"],[[30,[36,1],[[32,0,[\"toggleComponent\"]]],[[\"onClick\",\"collapsed\"],[[32,0,[\"toggleNavbar\"]],[32,0,[\"_collapsed\"]]]]],[30,[36,1],[[32,0,[\"contentComponent\"]]],[[\"collapsed\",\"onHidden\",\"onShown\"],[[32,0,[\"_collapsed\"]],[32,0,[\"onCollapsed\"]],[32,0,[\"onExpanded\"]]]]],[30,[36,1],[[32,0,[\"navComponent\"]]],[[\"linkToComponent\"],[[30,[36,1],[[32,0,[\"linkToComponent\"]]],[[\"onCollapse\"],[[32,0,[\"collapse\"]]]]]]]],[32,0,[\"collapse\"]],[32,0,[\"expand\"]],[32,0,[\"toggleNavbar\"]]]]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"nav\"],[16,0,[31,[\"navbar \",[32,0,[\"positionClass\"]],\" \",[32,0,[\"typeClass\"]],\" \",[32,0,[\"breakpointClass\"]],\" \",[32,0,[\"backgroundClass\"]]]]],[17,3],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"fluid\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n          \"],[18,2,[[32,1]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"component\",\"hash\",\"let\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-navbar.hbs"
    }
  });

  _exports.default = _default;
});