define("ember-bootstrap/templates/components/bs-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KvZSYz56",
    "block": "{\"symbols\":[\"&default\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,1],[[32,0,[\"alert\"]],\"alert\"],null],\" \",[30,[36,1],[[32,0,[\"fade\"]],\"fade\"],null],\" \",[30,[36,1],[[32,0,[\"dismissible\"]],\"alert-dismissible\"],null],\" \",[32,0,[\"typeClass\"]],\" \",[30,[36,1],[[32,0,[\"showAlert\"]],\"show\"],null]]]],[17,2],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"hidden\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,0,[\"dismissible\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"button\"],[24,0,\"close\"],[24,\"aria-label\",\"Close\"],[24,4,\"button\"],[4,[38,0],[[32,0],[32,0,[\"dismiss\"]]],null],[12],[2,\"\\n        \"],[10,\"span\"],[14,\"aria-hidden\",\"true\"],[12],[2,\"×\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"unless\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-alert.hbs"
    }
  });

  _exports.default = _default;
});