define("ember-bootstrap/templates/components/bs-dropdown/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x+Q1xHHI",
    "block": "{\"symbols\":[\"@class\",\"&attrs\",\"@registerChildElement\",\"@unregisterChildElement\",\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"_isOpen\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"ember-popper\",[[16,0,[31,[\"dropdown-menu \",[32,0,[\"alignClass\"]],\" \",[30,[36,0],[[32,0,[\"isOpen\"]],\"show\"],null],\" \",[32,1]]]],[24,\"tabindex\",\"-1\"],[17,2],[4,[38,1],[[32,3],\"menu\"],null],[4,[38,2],[[32,4],\"menu\"],null],[4,[38,3],[[32,0],\"menuElement\"],null]],[[\"@placement\",\"@popperTarget\",\"@renderInPlace\",\"@popperContainer\",\"@modifiers\",\"@onCreate\"],[[32,0,[\"popperPlacement\"]],[32,0,[\"toggleElement\"]],[32,0,[\"_renderInPlace\"]],[32,0,[\"destinationElement\"]],[32,0,[\"popperModifiers\"]],[32,0,[\"setFocus\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,5,[[30,[36,5],null,[[\"item\",\"link-to\",\"linkTo\",\"divider\"],[[30,[36,4],[[32,0,[\"itemComponent\"]]],null],[30,[36,4],[[32,0,[\"linkToComponent\"]]],null],[30,[36,4],[[32,0,[\"linkToComponent\"]]],null],[30,[36,4],[[32,0,[\"dividerComponent\"]]],null]]]]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"did-insert\",\"will-destroy\",\"ref\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-dropdown/menu.hbs"
    }
  });

  _exports.default = _default;
});