define("ember-bootstrap/templates/components/bs-form/element/layout/vertical/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PtZfNsqD",
    "block": "{\"symbols\":[\"&default\",\"@labelComponent\",\"@errorsComponent\",\"@helpTextComponent\"],\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"form-check\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n    \"],[1,[30,[36,0],[[32,2]],null]],[2,\"\\n    \"],[1,[30,[36,0],[[32,3]],null]],[2,\"\\n    \"],[1,[30,[36,0],[[32,4]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/layout/vertical/checkbox.hbs"
    }
  });

  _exports.default = _default;
});