define("ember-bootstrap/templates/components/bs-navbar/toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cm+K27ts",
    "block": "{\"symbols\":[\"&default\",\"&attrs\"],\"statements\":[[11,\"button\"],[16,0,[31,[\"navbar-toggler\",\" \",[30,[36,0],[[32,0,[\"collapsed\"]],\"collapsed\"],null]]]],[17,2],[24,4,\"button\"],[4,[38,1],[\"click\",[32,0,[\"handleClick\"]]],null],[12],[2,\"\\n\"],[6,[37,0],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"navbar-toggler-icon\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-navbar/toggle.hbs"
    }
  });

  _exports.default = _default;
});