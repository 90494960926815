define("ember-bootstrap/templates/components/bs-dropdown/toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RDOwZZ2g",
    "block": "{\"symbols\":[\"@inNav\",\"&attrs\",\"@registerChildElement\",\"@unregisterChildElement\",\"&default\"],\"statements\":[[11,\"a\"],[24,6,\"#\"],[16,0,[31,[\"dropdown-toggle \",[30,[36,0],[[32,1],\"nav-link\"],null]]]],[16,\"aria-expanded\",[32,0,[\"aria-expanded\"]]],[24,\"role\",\"button\"],[17,2],[4,[38,1],[\"keydown\",[32,0,[\"handleKeyDown\"]]],null],[4,[38,1],[\"click\",[32,0,[\"handleClick\"]]],null],[4,[38,2],[[32,3],\"toggle\"],null],[4,[38,3],[[32,4],\"toggle\"],null],[12],[2,\"\\n  \"],[18,5,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"on\",\"did-insert\",\"will-destroy\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-dropdown/toggle.hbs"
    }
  });

  _exports.default = _default;
});