define("ember-bootstrap/templates/components/bs-accordion/item/title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1WYTuvcB",
    "block": "{\"symbols\":[\"&attrs\",\"@disabled\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,0],[[32,0,[\"collapsed\"]],\"collapsed\",\"expanded\"],null],\" \",\"card-header\",\" \",[29]]]],[24,\"role\",\"tab\"],[17,1],[4,[38,1],[\"click\",[32,0,[\"handleClick\"]]],null],[12],[2,\"\\n\"],[2,\"    \"],[10,\"h5\"],[14,0,\"mb-0\"],[12],[2,\"\\n      \"],[10,\"button\"],[15,0,[31,[\"btn btn-link \",[30,[36,0],[[32,2],\"disabled\"],null]]]],[15,\"disabled\",[32,2]],[14,4,\"button\"],[12],[2,\"\\n        \"],[18,3,null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-accordion/item/title.hbs"
    }
  });

  _exports.default = _default;
});