define("ember-intl/-private/store/container", ["exports", "ember-intl/-private/store/translation"], function (_exports, _translation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @private
   * @hide
   */
  var _default = Ember.Object.extend({
    locales: Ember.computed('_translations', function () {
      return Array.from(this._translations.keys());
    }).readOnly(),
    init: function init() {
      this._super();

      this._translations = new Map();
    },
    lookupTranslationObject: function lookupTranslationObject(localeName) {
      var autogenerate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      if (this._translations.has(localeName) || !autogenerate) {
        return this._translations.get(localeName);
      }

      var translationObject = _translation.default.create({
        localeName: localeName
      });

      this._translations.set(localeName, translationObject);

      this.notifyPropertyChange('locales');
      return translationObject;
    },
    push: function push(locale, payload) {
      this.lookupTranslationObject(locale, true).addTranslations(payload);
    },
    has: function has(locale, key) {
      var translationObject = this.lookupTranslationObject(locale, false);

      if (translationObject) {
        return translationObject.has(key);
      }
    },
    lookup: function lookup(locale, key) {
      var translationObject = this.lookupTranslationObject(locale, false);

      if (translationObject && translationObject.has(key)) {
        return translationObject.find(key);
      }
    }
  });

  _exports.default = _default;
});