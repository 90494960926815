define("ember-bootstrap/templates/components/bs-dropdown/menu/divider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NcrULi3M",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,\"dropdown-divider\"],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-dropdown/menu/divider.hbs"
    }
  });

  _exports.default = _default;
});