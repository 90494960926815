define("ember-bootstrap/templates/components/bs-button-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pI+qE7B3",
    "block": "{\"symbols\":[\"&attrs\",\"@value\",\"@type\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,0],[[32,0,[\"vertical\"]],\"btn-group-vertical\",\"btn-group\"],null],\" \",[32,0,[\"sizeClass\"]],\" \",[30,[36,0],[[32,0,[\"justified\"]],[29]],null]]]],[17,1],[12],[2,\"\\n  \"],[18,4,[[30,[36,2],null,[[\"button\"],[[30,[36,1],[[32,0,[\"buttonComponent\"]]],[[\"buttonGroupType\",\"groupValue\",\"onClick\"],[[32,3],[32,2],[32,0,[\"buttonPressed\"]]]]]]]]]],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-button-group.hbs"
    }
  });

  _exports.default = _default;
});