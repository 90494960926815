define("ember-bootstrap/templates/components/bs-form/element/legend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KTt0c350",
    "block": "{\"symbols\":[\"&default\",\"@label\"],\"statements\":[[10,\"legend\"],[15,0,[31,[[30,[36,0],[[32,0,[\"invisibleLabel\"]],\"sr-only\"],null],\" \",[32,0,[\"labelClass\"]],\" \",[30,[36,0],[[32,0,[\"isHorizontalAndNotCheckbox\"]],\"col-form-label\"],null],\" \",[32,0,[\"sizeClass\"]]]]],[12],[2,\"\\n\"],[6,[37,0],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[1,[32,2]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/legend.hbs"
    }
  });

  _exports.default = _default;
});